<template>
  <default-layout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{ $store.state.pageIcon }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
    </template>
    <v-container class="px-0 pt-6" fluid>
      <v-card elevation="0">
        <v-img :src="require('@/assets/img/logo.jpg')" width="100px" class="mx-auto"></v-img>
        <v-card-text class="text-center">
          <h1>SMP Xavete</h1>
          <p>Cinta Kasih adalah awal dari Pendidikan</p>
          <p>
             <b>Telp Sekolah :</b> <br>082232767878 
          </p>
          <p>
             <b>Email Sekolah :</b> <br>smpxaverius1bl@gmail.com 
          </p>
          <p>
             <b>Alamat :</b> <br>Jln. Hasanudin No. 25 Telukbetung 
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue';
export default {

  name: "About",
  components: {
    DefaultLayout,
  },

  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.setPageTitle("About", "mdi-book-open-page-variant");
  },
}
</script>